.formItemStyles {
  :global {
    .ant-form-item {
      margin-bottom: 10px;
    }
    .mx-form > .ant-row {
      column-gap: 25px;
    }
  }
}
