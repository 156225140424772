// g6 主体位置

// :global {
//   canvas {
//     position: relative;
//     z-index: 1;
//   }
//   // 网格位置
//   .g6-grid-container {
//     z-index: 0 !important;
//     *,
//     ::before,
//     ::after {
//       // 覆盖 vuetify  background-repeat: no-repeat;
//       background-repeat: repeat;
//     }
//   }
// }

.left {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.drawerStyle {
  :global {
    .ant-drawer-content-wrapper {
      padding-top: 50px;
    }
    .ant-drawer-header {
      border-top: 2px solid #1aa3df;
    }
  }
}

.bgImg {
  background: url('./imgs/bg.svg');
}

.block {
  position: absolute;
  top: 20px;
  right: -160px;
  transform: translateX(-50%);
  gap: 23px !important;
}

.item {
  padding-left: 21px;
  position: relative;
  font-size: 14px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pipe {
    height: 16px;
    width: 16px;
    border-radius: 3px;
    margin-right: 6px;
  }
}

.bodyDivSty {
  width: 100%;
  height: calc(100% - 45px);
  display: flex;
  position: relative;
  // margin-left: -40px;
  // margin-right: -40px;
}

.dashBoardConfig {
  position: absolute;
  top: 18px;
  right: 20px;
}

.oneDashboardSty {
  z-index: 99;
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: #3e3e3e;
  height: 112px;
  width: 200px;
  border-radius: 2px;
  .dividerSty {
    height: 80%;
    border-color: #ffffff15;
  }
  .eachDivSty {
    padding: 16px;
    width: 200px;
    .alarmTitleSty {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .noPermissionSty {
      padding-top: 16px;
      color: #ffffff65;
      text-align: center;
    }
    .dashboardInnerContentSty {
      margin-right: -20px;
      padding-right: 10px;
      height: 56px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ffffff40;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #ffffff10;
      }
    }
    .dashboardTitleSty {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
    }
    .dashboardItemSty {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 8px;
      .dashboardItemTitleSty {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: 20px;
      }
      .dashboardItemContentSty {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.85);
        line-height: 20px;
      }
      .alarmColor {
        color: #ff4d4f;
      }
    }
  }
}

.drawertitleStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .drawertitleLeftStyle {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
  }
}
.translationStyles {
  animation: translateAnimation 1s ease-in-out infinite alternate;
}
@keyframes translateAnimation {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(8px);
  }
}

.tooltip {
  background: #000;
}
