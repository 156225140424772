.CardStyle {
  margin-bottom: 12px;
  margin-top: 48px;
  border: 0px;
  :global {
    .ant-card-body {
      // background-color: var(--component-background);
      background-color: rgba(38, 45, 55, 1);
    }
  }
  .processItemStyle {
    width: 49%;
    height: 320px;
    border-radius: 8px;
    margin: 6px 0.5%;
    :global {
      .ant-card-body {
        border-radius: 8px;
        width: 100%;
        height: 320px;
        background-color: #4a90e210;
      }
    }
    .addProcessBtnStyle {
      width: 100%;
      height: 320px;
      color: #4a90e2;
    }
  }
}

.AddProcessTabs {
  :global {
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
      background-color: #00adff;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff !important;
      border-radius: 4px;
      border-width: 2px;
    }

    .ant-badge-count {
      box-shadow: none;
    }
  }
}

.tab_item_title {
  display: flex;
  justify-content: center;
  align-items: center;
}
