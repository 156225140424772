.wrapper {
  display: flex;
  flex-direction: column;
}

.titleButtonUnChecked {
  background: var(--mx-module-bg-color) !important;
  border-radius: 2px !important;
  border: 1px solid var(--mx-light-border-color) !important;
  color: var(--mx-text-secondary-color) !important;
}

.titleButtonChecked {
  background: var(--mx-link-bg-color) !important;
  border-radius: 2px !important;
  border: 1px solid var(--mx-primary-color) !important;
  color: var(--mx-primary-color) !important;
}