.MonitorDeviceBtn {
  position: absolute;
  top: 25px;
  right: 30px;
}

.TabsSty {
  :global {
    .ant-tabs-tab {
      color: #fff;
      margin: 0 30px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .ant-tabs-nav-list div:first-child {
      margin-right: 0px;
    }
    .ant-radio-button-wrapper {
      height: 32px;
      line-height: 32px;
    }

    .ant-radio-button-wrapper:first-child {
      border-radius: 4px 0 0 4px;
    }
    .ant-radio-button-wrapper:last-child {
      border-left: none !important;
      border-radius: 0 4px 4px 0;
    }
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      border-bottom: 1px solid #ffffff20;
    }
  }
}

.pageFilter {
  background: var(--component-background);
  padding-top: 20px;
  :global {
    .mx-custom-filter-btns {
      display: none;
    }
  }
}

.layoutBtn {
  :global {
    .ant-radio-button-wrapper {
      line-height: 27px;
    }
  }
}

:global {
  #headControl,
  .head-message {
    display: none;
  }
  .video_page_box {
    display: flex;
    flex-direction: column;
    // height: calc(100vh - 128px);

    .mx-form-title {
      margin-bottom: 8px;
    }
  }
}

.video_box_outer {
  flex: 1;
  overflow: hidden;
  position: relative;
}
.video_box_inner {
  height: 100%;
  // overflow-y: scroll;
  .noData {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .viedoMode {
    border-radius: 0px 0px 0px 10px;
    background: rgba(0, 0, 0, 0.7);
    padding: 0 4px 0 8px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 8px;
    width: fit-content;
    z-index: 2;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }

  :global {
    .ant-row {
      margin-left: -12px !important;
      margin-right: -12px !important;
    }
    .ant-col {
      box-sizing: border-box;
    }
  }
}
.video_box_item {
  cursor: pointer;
  .err_block,
  .loading_block {
    aspect-ratio: 3/2;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
  }
  .loading_block {
    color: var(--primary-color);
  }
  .footer {
    cursor: auto;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: #131313;
    .left_text {
      color: rgba(255, 255, 255, 0.85);
    }
    .right_text {
      color: rgba(255, 255, 255, 0.65);
    }
  }
}
.button_area {
  padding-top: 32px;
}
.detail_video_button {
  cursor: pointer;
  .icon,
  .text {
    color: var(--primary-color);
  }
}
