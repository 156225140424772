.wrapperStyles {
  min-height: calc(100vh - 128px);
  padding: 20px;
}

.antLine :local {
  animation: antLine 30s infinite linear;
}

.customNode {
  width: 180px;
  height: 48px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  text-align: center;
  line-height: 46px;
  position: relative;
  &:hover {
    border: 1px solid #ffffff;
  }
  .node_text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .closeIcon {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    z-index: 10;
    background-image: url('../../icons/close-circle-outlined-normal@2x.png');
    background-size: cover;
    cursor: pointer;
    &:hover {
      background-image: url('../../icons/close-circle-outlined-hover@2x.png');
    }
  }
}

.firstNode {
  background: rgba(74, 144, 226, 0.25);
  border: 1px solid var(--mx-primary-color);
}

.lastNode {
  background: rgba(221, 170, 67, 0.25);
  border: 1px solid #ddaa43;
}

.otherNode {
  background: rgba(133, 78, 202, 0.25);
  border: 1px solid #854eca;
}

@keyframes antLine {
  to {
    stroke-dashoffset: -1000;
  }
}

.container {
  display: flex;
  gap: 16px;
  .stencil {
    width: 212px;
    height: calc(100vh - 408px);
    position: relative;
    border-right: 1px solid #dfe3e8;
    border: 1px solid rgba(255, 255, 255, 0.1);
    body {
      min-width: 40px;
    }
  }
  .noProcess {
    width: 212px;
    height: calc(100vh - 408px);
    position: relative;
    border-right: 1px solid #dfe3e8;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img {
      width: 48px;
      height: 48px;
    }
    .desc {
      width: 136px;
      line-height: 22px;
      margin-top: 24px;
      color: rgba(255, 255, 255, 0.65);
      font-size: 14px;
      font-weight: 408;
    }
  }

  .graphBox {
    flex: 1;
    height: calc(100vh - 408px);
    background: url('~@maxtropy/components/es/components/Wrapper/DarkWrapper/bg.svg');
    border: 1px solid rgba(255, 255, 255, 0.05);
    position: relative;

    .noMap {
      position: absolute;
      top: calc(50% - 56px);
      left: calc(50% - 140px);
      text-align: center;

      .img {
        width: 48px;
        height: 48px;
      }
      .desc {
        width: 280px;
        line-height: 22px;
        margin-top: 24px;
        color: rgba(255, 255, 255, 0.65);
        font-size: 14px;
        font-weight: 408;
      }
    }

    .graph {
      flex: 1;
      height: calc(100vh - 408px);
      body {
        min-width: 40px;
      }
    }
  }

  :global {
    .x6-widget-stencil-title {
      display: none;
    }
    .x6-widget-stencil {
      background-color: #232324;
    }
    // .x6-edge-tool {
    //   path {
    //     display: none;
    //   }
    // }
  }
}

.popconfirmSty {
  :global {
    .ant-popover-inner {
      background-color: #1f1f1f;
      background-clip: padding-box;
      border-radius: 2px;
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
      padding: 12px;
    }
    .ant-popover-inner-content {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-popconfirm .ant-popconfirm-inner-content {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-popconfirm .ant-popconfirm-message {
      position: relative;
      margin-bottom: 8px;
      color: rgba(255, 255, 255, 0.85);
      font-size: 14px;
      display: flex;
      flex-wrap: nowrap;
      align-items: start;
    }
    .ant-btn-default {
      color: #fff;
      background-color: #232324;
      border-color: rgba(0, 173, 255, 0.5);
      box-shadow: 0 2px 0 rgba(255, 255, 255, 0.04);
    }
    .ant-btn-primary {
      color: #fff;
      background-color: #427dc3;
      box-shadow: 0 2px 0 rgba(0, 19, 47, 0.37);
    }
    .ant-popover-arrow:before {
      background-color: #1f1f1f;
    }
  }
}
