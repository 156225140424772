.col {
  &.checked {
    background: var(--primary-color);
    color: rgb(var(--base-text-color));
  }
  &.disabled {
    color: rgba(var(--base-text-color), .3);
    cursor: not-allowed;
    pointer-events: none;
  }
}
.buttonWrapper {
  position: fixed;
  bottom: 0;
  background: rgba(var(--base-text-color), 0.1);
  width: calc(100% - 268px);
  margin-left: -30px;
  padding: 14px 60px;
  text-align: right;
}
