.infoCard {
  margin-bottom: 16px;
  height: 80px;
  background-color: var(--mx-base-bg-color);
  :global {
    .mx-form > .ant-row {
      column-gap: 2px;
    }
  }
  .dividerStyle {
    margin-top: 5px;
    margin-right: 16px;
    height: 50px;
    border-color: var(--mx-light-border-color);
  }
}
