.DividerStyle {
  :global {
    margin: 10px 0;
    border-block-start-color: rgba(255, 255, 255, 0.12);
  }
}

.FormCardItemStyle {
  :global {
    .ant-form-item {
      margin: 4px 0;
      margin-bottom: 4px !important;
    }
  }
}

.TagStyle {
  display: flex;
  align-items: center;
  :global {
    .ant-tag {
      background-color: #fff;
      border-color: #fff;
    }
  }
}

.relatedPartyMaint {
  :global {
    .ant-table-tbody > tr > td {
      padding: 0px !important;
      line-height: 40px;
    }
  }
}
