.customNode {
  color: rgba(255, 255, 255, 0.85);
}

.customNodeLeft {
  width: 160px;
  height: 48px;
  border-radius: 2px;
  background: #2d2d2d;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid transparent;

  .imgCon {
    width: 24px;
    height: 24px;
    margin: 0 10px 0 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &:hover {
    border-color: #4a90e2;
  }
}

.customNodeRight {
  position: relative;
  .nodeTitle {
    position: absolute;
    font-size: 12px;
    line-height: 20px;
    top: -24px;
    left: 0;
    width: max-content;
  }

  .nodeIcon {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;

    .nodeImg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .errorIcon {
      position: absolute;
      width: 24px;
      height: 24px;
      left: 3px;
      bottom: 5px;
    }
    .errorItem {
      position: absolute;
      width: 24px;
      height: 24px;
      background-color: rgba(230, 66, 66, 0.4);
      border-radius: 50%;
      bottom: 3px;
      left: 3px;
      &::before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        opacity: 0.7;
        background-color: rgba(230, 66, 66, 0.6);
        animation: scaless 1s infinite cubic-bezier(0, 0, 0.49, 1.02);
      }
    }

    @keyframes scaless {
      0% {
        transform: scale(1);
      }

      75% {
        transform: scale(2);
      }

      100% {
        opacity: 0;
      }
    }
  }

  .closeIcon {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    z-index: 100;
    background-image: url('../../icons/close-circle-outlined-normal@2x.png');
    background-size: cover;
    cursor: pointer;
    &:hover {
      background-image: url('../../icons/close-circle-outlined-hover@2x.png');
    }
  }
}

.ggsPopConfirm {
  :global {
    .ant-popover-inner {
      background: #1f1f1f;
    }
  }
}

.nodeTip {
  :global {
    .ant-tooltip-arrow:before {
      display: none;
    }
  }
  .titleWrapper {
    min-width: 200px;
    font-size: 12px;
    line-height: 20px;
    color: var(--mx-text-base-color);
    .port {
      margin-top: 5px;
    }
    .row {
      display: flex;
      align-items: center;
      margin-top: 8px;

      justify-content: space-between;

      .nodeName {
        flex-shrink: 0;
        margin-right: 8px;
      }
    }
  }
}
