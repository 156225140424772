.CollapseStyle {
  margin-bottom: 12px;
  border-left-width: 4px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #4a90e210 !important;
  border-bottom: transparent;
  border-top: transparent;
  border-right: transparent;

  :global {
    .ant-collapse-item:last-child > .ant-collapse-content {
      border-top: transparent;
      border-bottom: transparent;
    }
    .ant-collapse-expand-icon {
      position: absolute;
      right: 134px;
      bottom: 44px;
      z-index: 999;
      height: 24px;
    }
    .ant-collapse-header-text {
      width: 100%;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px;
      // background-color: #4a90e220 !important;
    }

    .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      font-size: 14px;
      color: var(--primary-color);
      line-height: 24px;
      -webkit-font-smoothing: auto;
    }

    .ant-collapse-extra {
      position: absolute;
      bottom: 40px;
      right: 20px;
    }
  }
}

.SpaceBtnStyle {
  position: absolute;
  top: 36px;
  right: 48px;
  z-index: 99;
}

.search {
  :global {
    .ant-input {
      background-color: transparent;
    }
  }
}

.filterPosition {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow-x: hidden;
  position: absolute;
  top: 8px;
  right: 40px;
}

.formStyle {
  width: 420px;
  .itemFormSty > div {
    display: flex;
    justify-content: flex-end;
  }
}


