.btn {
  &.checked {
    background: var(--primary-color);
    color: rgb(var(--base-text-color));
  }
  &.disabled {
    color: rgba(var(--base-text-color), 0.3);
    cursor: not-allowed;
    pointer-events: none;
  }
}
.title {
  position: relative;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: var(--heading-color);
  margin-left: 8px;
}
.title:before {
  position: absolute;
  content: '';
  width: 4px;
  height: 16px;
  background: var(--primary-color);
  left: -8px;
  top: 2px;
}

.defaultBtn,
.defaultBtn:active,
.defaultBtn:hover,
.defaultBtn:focus {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background: transparent;
}

.deleteBtn,
.deleteBtn:active,
.deleteBtn:hover,
.deleteBtn:focus {
  background: transparent;
  border-color: #c1c1c1;
  color: var(--text-color-secondary);
}

.row {
  background: #262d36;
  padding: 10px;
  margin: 0 !important;
}
.top {
  display: flex;
  // background: #f0f0f0;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  .red {
    color: #f5222d;
  }
  .icon {
    margin-right: 5px;
    padding: 4px;
    border-radius: 20px;
    color: #5c5c5c;
    font-size: 12px;
    background: #d8d8d8;
  }
}

.buttonWrapper {
  position: fixed;
  bottom: 0;
  background: rgba(var(--base-text-color), 0.1);
  width: calc(100% - 268px);
  margin-left: -30px;
  padding: 14px 60px;
  text-align: right;
  z-index: 999;
}

.EDFAFF {
  // background-color: #edfaff;
}

.FFFCEC {
  // background-color: #fffcec;
}

.EFF9F0 {
  // background-color: #eff9f0;
}

.defaultCol {
  background-color: #262d36;
}
