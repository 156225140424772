.container {
  display: flex;
  flex-direction: column;
  .headerWrapper {
    display: flex;
    align-items: center;
    height: 56px;
    margin-left: 12px;
    margin-top: 12px;
    .title {
      color: var(--text-color);
      font-weight: 500;
      font-size: 16px;
    }
   
    .titleBorder {
      width: 16px;
      height: 16px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
  .content_box {
    height: 260px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chart_box{
      width: 57%;
      height: 100%;
      .empty_box {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}