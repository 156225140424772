.checkGroupAll {
  max-height: 240px;
  overflow-x: auto;
  :global {
    .ant-checkbox-wrapper {
      width: 160px;
    }
    .mx-checkbox-group {
      flex-wrap: wrap;
    }
  }
}
