.stationPanel {
  background: var(--mx-base-bg-color);
  border-radius: 2px;
  margin-bottom: 16px;
  padding: 16px 24px;

  .titleInfo {
    line-height: 24px;
    display: flex;
    > div {
      font-size: 16px;
      margin-right: 8px;
    }
    :global {
      .ant-tag {
        height: 22px;
      }
      .mx-btn {
        flex: 1;
        text-align: right;
        padding: 0;
        margin-right: 0;
        .ant-btn {
          padding: 0;
        }
      }
    }
  }

  .stationInfo {
    display: flex;
    margin-top: 16px;

    > div {
      flex: 1 1 20%;
      max-width: 20%;
      padding-left: 8px;
      position: relative;
      display: flex;
      &:first-child {
        padding: 0;
      }
      &:not(:first-child)::before {
        display: inline-block;
        content: '';
        width: 1px;
        background: var(--mx-disabled-bg-color);
        height: 16px;
        position: absolute;
        left: 0;
        top: 3px;
      }

      > label {
        color: var(--mx-text-secondary-color);
      }

      > span {
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
      }
    }
  }

  .graphContainer {
    background: url('~@maxtropy/components/es/components/Wrapper/DarkWrapper/bg.svg');
    border: 1px solid var(--mx-base-border-color);
    height: 240px;
    opacity: 0.9;
    margin-top: 16px;
    position: relative;

    .graph {
      flex: 1;
      height: 100%;
      body {
        min-width: 24px;
      }
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
