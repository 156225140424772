.rightContent {
  padding: 20px;

  .contentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      padding-bottom: 6px;
      padding-right: 4px;
      width: 24px;
      height: 24px;
    }
    .titleStyle {
      font-size: 18px;
      font-weight: 600;
      color: var(--mx-text-base-color);
    }
    .topoImgBtn {
      margin-left: auto;
    }
  }

  .infoCard {
    margin: 16px 0 4px 0;
    height: 80px;
    background-color: var(--mx-base-bg-color);
    :global {
      .mx-form > .ant-row {
        column-gap: 25px;
      }
    }
    .dividerStyle {
      margin-top: 5px;
      margin-right: 16px;
      height: 50px;
      border-color: var(--mx-light-border-color);
    }
  }
}

.tab_box {
  position: relative;
}

.line {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom: 1px solid var(--mx-tabs-base-border-color);
  height: 47px;
  width: 100%;
}

.tabs {
  width: 100%;
  // :global {
  //   .ant-tabs-top > .ant-tabs-nav::before,
  //   .ant-tabs-bottom > .ant-tabs-nav::before,
  //   .ant-tabs-top > div > .ant-tabs-nav::before,
  //   .ant-tabs-bottom > div > .ant-tabs-nav::before {
  //     border-color: rgba(255, 255, 255, 0.35);
  //   }
  // }
}
