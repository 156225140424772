.content {
  padding: 14px 32px;
  :global {
    .ant-select.ant-select-in-form-item {
      max-width: 474px;
    }
    .mx-input {
      max-width: 474px;
    }
    .ant-form-item-label > label {
      width: 130px;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    color: var(--mx-text-base-color);
    line-height: 24px;
    margin-bottom: 16px;
  }
}
