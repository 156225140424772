.itemCol {
  display: flex;
  align-items: center;
}

.nameSty {
  font-size: 16px;
  margin-right: 8px;
}

.ColSpanStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: white;
}

.subTitle_sty {
  font-size: 12px;
  color: #ffffff65;
}

.CollapseStyle {
  margin-bottom: 12px;
  border-left-width: 4px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #4a90e210 !important;
  border-bottom: transparent;
  border-top: transparent;
  border-right: transparent;

  :global {
    .ant-collapse-item:last-child > .ant-collapse-content {
      border-top: transparent;
      border-bottom: transparent;
    }
    .ant-collapse-expand-icon {
      position: absolute;
      right: 142px;
      bottom: 44px;
      z-index: 999;
      height: 24px !important;
    }
    .ant-collapse-header-text {
      width: 100%;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px;
      background-color: #4a90e210 !important;
      padding-bottom: 8px;
    }

    .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      font-size: 14px;
      color: var(--primary-color);
      line-height: 24px;
      -webkit-font-smoothing: auto;
    }

    .ant-collapse-extra {
      position: absolute;
      bottom: 40px;
      right: 20px;
    }
  }
}
.tab_content_wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .CardDetailStyle {
    background: rgba(255,255,255,0.05);
    min-height: 160px;
    width: 32%;
    // height: 300px;
    margin: 8px;
    border: 0px;
  }
}

.ColStyle {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #ffffff15;
  padding-left: 20px;
  margin: 10px;
}

.AddProcessTabs {
  margin-left: 20px;
  .switchBtn {
    padding-top: 16px;
    margin-bottom: 16px;
    margin-left: 8px;
  }
  :global {
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
      background-color: #00adff;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff !important;
      border-radius: 4px;
      border-width: 2px;
    }

    .ant-badge-count {
      box-shadow: none;
    }
  }
}
