.bgImg {
  background: url('~@maxtropy/components/es/components/Wrapper/DarkWrapper/bg.svg');
}

.wrapper {
  width: 440px;
  height: 100%;
  border: 1px solid #ffffff20;
  position: relative; 
  margin-top: 16px
}

.block {
  position: absolute;
  top: 20px;
  right: -120px;
  transform: translateX(-50%);
  gap: 23px !important;
}

.item {
  padding-left: 21px;
  position: relative;
  font-size: 14px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pipe {
    height: 16px;
    width: 16px;
    border-radius: 3px;
    margin-right: 6px;
  }
}
