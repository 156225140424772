.energyMediumList {
  .energyMediumItem {
    margin-bottom: 24px;
    .energyMediumName {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
    }
    :global {
      .mx-tag-border-solid-info.ant-tag {
        margin-top: 8px;
        cursor: pointer;
      }
    }
  }
}
